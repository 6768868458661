import { useEffect } from 'react';

import { useNavWithTokens } from '../contexts/Nav';

import useInitialPage from 'apps/embedded-cbc/hooks/use-initial-page';

export default function Home() {
  const { isLoading, pathName } = useInitialPage();
  const { push } = useNavWithTokens();

  useEffect(() => {
    if (!isLoading && !!pathName) {
      push(pathName);
    }
  }, [isLoading, pathName, push]);

  return <></>;
}
